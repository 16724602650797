@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .bg-site-bg {
    background-color: #151518;
  }
}

body {
  @apply text-nsdr-light font-sans bg-site-bg;
  font-family: 'Hanken Grotesk', sans-serif;
}
/* Custom utility classes */
.bg-gradient-subtle {
  @apply bg-gradient-to-br from-nsdr-dark to-nsdr-light bg-opacity-10;
}

.hover-glow {
  @apply transition-all duration-300 hover:shadow-lg hover:shadow-nsdr-accent/20;
}

.hide-persistent-player .persistent-player {
  display: none;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
}

.transition-opacity {
  transition: opacity 0.5s ease-in-out;
}